import { Severity } from '@sentry/types';
import type { EditorScriptFlowAPI } from '@wix/yoshi-flow-editor';
import { toError } from './errors';

interface CaptureExceptionOptions {
  print: boolean;
}

export function captureException(
  flowAPI: EditorScriptFlowAPI,
  err: unknown,
  options: Partial<CaptureExceptionOptions> = {},
) {
  if (typeof err === 'object' && err !== null && !(err instanceof Error)) {
    flowAPI.errorMonitor.addBreadcrumb({
      level: Severity.Error,
      message: 'Error object.',
      data: err,
    });
  }
  flowAPI.errorMonitor.captureException(toError(err));
  if (options.print) {
    console.error(err);
  }
}
